import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Main } from '../main';

interface Props {
  pageTitle: string;
  imagePath?: string;
  description?: string;
  children: JSX.Element | JSX.Element[];
}

const Layout = ({ pageTitle, imagePath, children, description }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
      sitePage {
        path
      }
    }
  `);

  return (
    <Main
      title={data.site.siteMetadata.title}
      siteUrl={data.site.siteMetadata.siteUrl}
      path={data.sitePage.path}
      pageTitle={pageTitle}
      imagePath={imagePath || ''}
      description={description || 'This is ocknamos ' + pageTitle}
    >
      <div
        className="
          w-full
          p-4
          flex
          items-center
          flex-col
        "
      >
        {children}
      </div>
    </Main>
  );
};

export default Layout;
