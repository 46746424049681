import * as React from 'react';
import { MenuBar } from './ui/menu-bar';
import { Helmet } from 'react-helmet';

interface MainProps {
  title: string;
  pageTitle: string;
  siteUrl: string;
  path: string;
  imagePath: string;
  description: string;
  children: JSX.Element | JSX.Element[];
}

interface MainState {
  isMenuOpen: boolean;
  scroll: number;
  hideMenuBar: boolean;
}

// Todo: main classを実装する
export class Main extends React.Component<MainProps, MainState> {
  constructor(props: MainProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      scroll: 0,
      hideMenuBar: false,
    };
  }

  scrollContent: HTMLElement | null = null;

  componentDidMount() {
    this.setScroll();
  }

  componentWillUnmount() {
    this.unsetScroll();
  }

  // Todo: アニメーションの追加
  setScroll() {
    this.scrollContent =
      this.scrollContent ?? document.getElementById('scroll-contents');
    if (this.scrollContent === null) {
      return;
    }

    this.scrollContent.addEventListener('scroll', this.getScroll.bind(this));
  }

  unsetScroll() {
    if (this.scrollContent === null) {
      return;
    }
    this.scrollContent?.removeEventListener(
      'scroll',
      this.getScroll.bind(this)
    );
  }

  getScroll() {
    const scrollBuffer = 60;

    const scrollLength =
      (this.scrollContent?.scrollTop || 0) - this.state.scroll;
    if (scrollLength > scrollBuffer) {
      this.setState({
        scroll: this.scrollContent?.scrollTop || 0,
        hideMenuBar: true,
      });
    } else if (scrollLength < -scrollBuffer) {
      this.setState({
        scroll: this.scrollContent?.scrollTop || 0,
        hideMenuBar: false,
      });
    }
  }

  onMenuButtonClick(isMenuOpen: boolean) {
    if (isMenuOpen) {
      setTimeout(() => {
        this.setState({ isMenuOpen });
      }, 400);
    } else {
      this.setState({ isMenuOpen });
    }
  }

  render() {
    return (
      <main>
        <Helmet
          title={`${this.props.pageTitle} | ${this.props.title}`}
          meta={[
            { charSet: 'utf-8' },
            { name: 'og:type', content: 'summary_large_image' },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:creator', content: '@ocknamo' },
            {
              property: 'og:url',
              content: `${this.props.siteUrl}${this.props.path}`,
            },
            { property: 'og:title', content: `${this.props.title}` },
            {
              property: 'og:image',
              content: `${this.props.siteUrl}${
                this.props.imagePath || '/bug.png'
              }`,
            },
            {
              property: 'og:description',
              content: `${this.props.description}`,
            },
          ]}
        ></Helmet>
        <MenuBar
          title={this.props.title}
          onMenuButtonClick={(t) => this.onMenuButtonClick(t)}
          hide={this.state.hideMenuBar}
        ></MenuBar>
        <div
          id="scroll-contents"
          className={
            'overflow-y-scroll transition-all duration-500 h-screen ' +
            (this.state.isMenuOpen ? 'hidden ' : '') +
            (this.state.hideMenuBar
              ? ''
              : 'm-menubar md:m-lmenubar h-maincontent md:h-lmaincontent')
          }
        >
          <h1 className="text-center mt-4 sm:m-4">{this.props.pageTitle}</h1>
          {this.props.children}
        </div>
      </main>
    );
  }
}
