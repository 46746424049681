import { Link } from 'gatsby';
import * as React from 'react';

interface MenuBarProps {
  title: string;
  onMenuButtonClick: (isMenuOpen: boolean) => void;
  hide: boolean;
}

interface MenuBarState {
  isMenuOpen: boolean;
  menuAnimationTrigger: boolean;
}

export class MenuBar extends React.Component<MenuBarProps, MenuBarState> {
  constructor(props: MenuBarProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      menuAnimationTrigger: false,
    };
  }

  isMounting = true;

  toggleButton() {
    const next = !this.state.isMenuOpen;
    this.setState({ isMenuOpen: next });

    setTimeout(() => {
      // Fix memory leak.
      if (!this.isMounting) {
        return;
      }
      this.setState({ menuAnimationTrigger: next });
    }, 200);

    this.props.onMenuButtonClick(next);
  }

  componentWillUnmount() {
    this.isMounting = false;
  }

  render() {
    const path = typeof window !== 'undefined' ? window.location.pathname : '/';

    return (
      <main
        className={
          `absolute
          top-0
          w-full
          h-menubar
          md:h-lmenubar
          z-50
          transition-transform
          duration-500 transform ` +
          (this.props.hide ? '-translate-y-14 md:-translate-y-20' : '')
        }
      >
        <div>
          <div
            className={
              `bg-white
                transition-height
                duration-500
                ease-in-out
                h-11
                md:h-14
                relative
                m-2
                p-2
                md:p-4
                border-2
                border-solid
                border-gray-400
                rounded-lg
                xl:flex
                xl:justify-center
                ` + (this.state.isMenuOpen ? ' h-fullmenu md:h-fullmenu' : '')
            }
          >
            <p className="text-center text-gray-500 xl:mr-10 xl:text-xl xl:leading-5">
              {this.props.title}
            </p>
            <button
              onClick={() => this.toggleButton()}
              className="
                absolute
                top-1
                right-2
                text-2xl
                md:text-4xl
                md:mr-3
                xl:hidden
                text-blue-600"
            >
              {this.state.isMenuOpen ? 'x' : '≡'}
            </button>

            <div
              className={
                this.state.menuAnimationTrigger
                  ? 'flex flex-col justify-center h-5/6 space-y-16 mt-10 text-3xl sm:text-5xl ml-8 sm:ml-24 '
                  : ' hidden'
              }
            >
              <Link
                to="/"
                onClick={() => this.toggleButton()}
                className={
                  'text-gray-600 hover:text-gray-800 ' +
                  (path === '/' ? 'text-opacity-60' : '')
                }
              >
                Home
              </Link>
              <Link
                to="/works"
                onClick={() => this.toggleButton()}
                className={
                  'text-gray-600 hover:text-gray-800 ' +
                  (path === '/works' ? 'text-opacity-60' : '')
                }
              >
                Works
              </Link>
              <Link
                to="/blog"
                onClick={() => this.toggleButton()}
                className={
                  'text-gray-600 hover:text-gray-800 ' +
                  (path === '/blog' ? 'text-opacity-60' : '')
                }
              >
                黄金虫ブログ
              </Link>
            </div>
            <nav className="hidden xl:flex space-x-4 text-xl xl:leading-5">
              <Link
                to="/"
                className={
                  `
                  text-green-900
                  hover:text-green-700 ` + (path === '/' ? 'opacity-60' : '')
                }
              >
                Home
              </Link>
              <Link
                to="/works"
                className={
                  `
                    text-green-900
                    hover:text-green-700 ` +
                  (path === '/works' ? 'opacity-60' : '')
                }
              >
                Works
              </Link>
              <Link
                to="/blog"
                className={
                  `
                  text-green-900
                  hover:text-green-700 ` +
                  (path === '/blog' ? 'opacity-60' : '')
                }
              >
                BLOG
              </Link>
            </nav>
          </div>
        </div>
      </main>
    );
  }
}
